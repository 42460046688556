import HostConfig from './Host.config';
import CMSOBJ from '$DATA/stage.live.static.id';
const URL = HostConfig.Config.HostApi;
const BffscApiURL = HostConfig.Config.BffscApi;
const LOCAL_HOST = HostConfig.Config.LocalHost;
export const SPORT_API = HostConfig.Config.SportApi;
export const StrapiApi = HostConfig.Config.StrapiCmsUrl;

const APISET_Platform = 'Platform=Desktop';
const APISET_APIVERSION3 = 'api-version=3.0';
export const APISET = '?api-version=2.0&' + APISET_Platform;
export const APISETS = '&api-version=2.0&' + APISET_Platform;
export const API8SET = '?api-version=8.0&' + APISET_Platform;
export const API8SETS = '&api-version=8.0&' + APISET_Platform;
export const ApiPort = {
	URL,
	BffscApiURL,
	LOCAL_HOST,
	PostLogin: BffscApiURL + '/api/Auth/Login' + API8SET, // 登录
	Captcha: URL + '/api/Captcha/' + APISET, // 验证码
	LogoutAPI: BffscApiURL + '/api/Auth/Logout' + API8SET, // 注销登录
	PostRegister: BffscApiURL + '/api/Member/Register' + API8SET, // 注册
	GETMemberlistAPI: BffscApiURL + '/api/Member' + API8SET, // 会员中心个人详情数据
	POSTMemberlistAPI: URL + '/api/Member' + APISET, // 更新会员中心资料
	PATCHMemberlistAPI: BffscApiURL + '/api/Member' + API8SET, // PATCH更新单个秘密答案的时候使用
	PUTMemberlistAPI: BffscApiURL + '/api/Member' + API8SET, // PUT更新会员的个人资料
	POSTEmailVerifyAPI: BffscApiURL + '/api/Verification/Email' + API8SET, // 验证邮箱
	POSTEmailVerifyLink: URL + '/api/Verification/Email/VerifyLink' + APISET, // 验证邮箱链接
	POSTEmailTAC: URL + '/api/Verification/Email/VerifyTac' + APISET,
	POSTPhoneVerifyAPI: BffscApiURL + '/api/Verification/Phone' + API8SET, // 验证手机号码发送验证码
	POSTPhoneVerifyTAC: BffscApiURL + '/api/Verification/Phone' + API8SET,
	MemberFlagsStatus: BffscApiURL + '/api/Member/CustomFlag' + API8SET, //返回存款验证步骤，是否可以修改验证的手机号码
	VerificationAttempt: BffscApiURL + '/api/Verification/OTPAttempts' + API8SET, //验证相关剩余的次数
	GETDepositDetailsAPI: URL + '/api/Payment/Method/Details?transactionType=deposit' + APISETS + '&method=', // 返回所选付款方式的详情
	GETBffscDepositDetailsAPI: BffscApiURL + '/api/Payment/Methods/Details?transactionType=deposit' + APISETS + '&paymentMethod=', // 返回ODP所选付款方式的详情
	POSTBffscDepositDetailsAPI2: BffscApiURL + '/api/Payment/v2.0/Methods/Details?api-version=1.0&' + APISET_Platform,
    // GETWithdrawalDetailsAPI: URL + '/api/Payment/Method/Details?transactionType=Withdrawal&method=LB' + APISETS, // 获取提款详情
	GETPaymentlistAPI: URL + '/api/Payment/Methods?transactionType=Deposit' + APISETS, // 返回平台开启的钱包列表
	GETBonuslistAPI: BffscApiURL + '/api/Bonus/v2.0' + API8SET + '&transactionType=', // 返回优惠信息
	POSTCalculateAPI: URL + '/api/Bonus/Calculate' + APISET, // 向后台传优惠信息的ID计算返回的结果
	POSTApplications: URL + '/api/Payment/Application' + APISET, // 开始充值
	GETWallets: BffscApiURL + '/api/Transfer/Wallets' + API8SET, // 返回可以转账的账户
	POSTTransfer: BffscApiURL + '/api/Transfer/Applications' + API8SET, // 转账and一键转账
	GETBalance: BffscApiURL+ '/api/Balance' + API8SET + '&wallet=', // 余额
	GETtransaction: URL + '/api/Payment/Applications?transactionType=Deposit&paymentMethod=', // 存款的交易记录
	GETWithdrawalReport: URL + '/api/Payment/Applications?transactionType=Withdrawal&paymentMethod=', // 提款的交易记录
	GETTransferReport: BffscApiURL + '/api/Transfer/Histories' + API8SET, // 转账的交易记录
	GETPromotionCategories: URL + '/api/CMS/PromotionCategories' + APISET, // 优惠类别
	POSTPromotionList: URL + '/api/CMS/Promotions?promoCategory=', // 优惠详情
	POSTPromotionApplications: BffscApiURL + '/api/Promotion/Application' + API8SET, // 申请优惠
	GETManualPromoMaxApplicant: BffscApiURL + '/api/Promotion/ApplicationInfo/ManualPromo?info=CheckMaxApplicant' + API8SETS, //取得Manual優惠是否已達最大申請人數
	GETNewsUrl: URL + '/api/News' + APISET, // 消息公告
	GETSlotListUrl: URL + '/api/Games?gametype=desktopslot&vendorPlatform=web' + APISETS, // 电子游戏游戏列表
	GETChessListUrl: URL + '/api/Games?gametype=desktopp2p&vendorPlatform=web' + APISETS, // 棋牌游戏列表
	GETSlotCategories: URL + '/api/Games/Slot/Categories' + APISET, // 游戏类型分类
	GETCanWithdrawalPay: URL + '/api/Payment/Methods?transactionType=Withdrawal' + APISETS, // 返回提款的方式可用列表
	GETWithdrawalbank:
		URL +
		'/api/Payment/Method/Details?transactionType=Deposit&isMobile=false&Withdrawal&method=LB&hostName=' +
		LOCAL_HOST +
		APISETS, // 提款新账户 返回的银行列表
	GETMemberBanksfirst: URL + '/api/Payment/MemberBanks' + APISET, // 获取提款首选账户
	GETMemberBanksfirst2: BffscApiURL + '/api/Payment/MemberBanksByType?platform=desktop&culture=zh-cn&accountType=Withdrawal', // 获取提款首选账户
	POSTRememberBanks: URL + '/api/Payment/MemberBank' + APISET, // 提款记住我
	PUTMemberPassword: BffscApiURL + '/api/Auth/ChangePassword/?oldPasswordRequired=true' + API8SETS, // 修改密碼
	PTMemberPwd: URL + '/api/Member/Password' + APISET, //安全验证修改密码
	PATCHMemberBanksDefault: URL + '/api/Payment/MemberBank/SetDefault', // 个人资料设置默认银行账户
	DELETEMemberBanksDefault: URL + '/api/Payment/MemberBank/', // 删除银行卡
	GETCategory: URL + '/api/ProfileMasterData?category=Nations' + APISETS, // 国家
	GETCheckVendor: URL + '/api/Vendor/PT' + APISET, // 检查PT金币皇账户是否存在
	GETCheckVendorUsername: URL + '/api/Vendor/PT/Username' + APISET, // 检查PT金币皇用户名是否存在
	PUTPTChangePWD: URL + '/api/Vendor/0/Password?redirectUrl=' + LOCAL_HOST + APISETS, // 修改PT金币皇账户密码
	GETSelfExclusions: BffscApiURL + '/api/Member/SelfExclusion' + API8SET, // 行为限制详情
	PUTSelfExclusions: BffscApiURL + '/api/Member/SelfExclusion?redirectUrl=' + LOCAL_HOST + API8SETS, // 设置限制
	POSTForgetPassword: URL + '/api/Member/ForgetPassword?redirectUrl=' + LOCAL_HOST + APISETS, // 忘记密码
	POSTCZForgetPassword: URL + '/api/Member/ForgetPassword?', // 重置密码
	POSTForgetUsername: BffscApiURL + '/api/Auth/ForgetUsername/Email' + API8SET + '&', // 忘记用户名
	POSTEmailForgetPassword: BffscApiURL + `/api/Auth/ForgetPassword/Email` + API8SET, // 忘记密码验证邮箱
	GETDomainUrl: BffscApiURL + '/api/App/Domain?hostName=' + LOCAL_HOST + API8SETS, // 获取合作伙伴地址
	GETBonusOptions: URL + '/api/Bonus' + APISET, // 红利记录的下拉菜单数据
	GETBonusApplications: URL + '/api/Bonus' + APISET, // 红利记录
	// GETBonusApplications: URL + '/api/Bonus/Applications?', // 红利记录
	POSTPaymentConfirmStep: URL + '/api/Payment/Application/', // 确认交易
	POSTPaymentConfirmStep2: BffscApiURL + '/api/Payment/Applications/ConfirmStep/', // 确认交易
	GETUserBetHistory: BffscApiURL + '/api/Member/DailyTurnover' + API8SET, // 投注记录
	GETNewBetHistory: URL + '/api/Member/MemberDailyTurnoverByProductType?', // 投注记录新api
	GetNewBetHistoryDetail: URL + '/api/Member/MemberDailyTurnover' + APISET, // 获取投注记录详情
	GETLiveChat: BffscApiURL + '/api/LiveChat/Url' + API8SET, // 在线客服
	POSTNoCancellation: URL + '/api/Payment/Application/Cancellation/', // 取消交易

	GETExternalPromotions: BffscApiURL + '/api/Promotion/ExternalPromotions?' + APISET_Platform + '&' + APISET_APIVERSION3,
	GETExternalPromotionLink: BffscApiURL + '/api/Promotion/ExternalPromotionLink?' + APISET_Platform + '&' + APISET_APIVERSION3,

	GETHomeBannertrue: URL + '/api/CMS/Banners?pageType=Banner&isLogin=true&category=1' + APISETS, // 登录后的首页Banner
	GETHomeBannerfalse: URL + '/api/CMS/Banners?pageType=Banner&isLogin=false&category=1' + APISETS, // 未登录后的首页Banner
	POSTBonusClaim: BffscApiURL + '/api/Bonus/Claim' + API8SET, // 红利奖励领取
	CancelBonusPromotion: BffscApiURL + '/api/Bonus/Cancellation' + API8SET, // 我的优惠撤销
	GETSettingwithdrawal: URL + '/api/Setting?key=withdrawalverification' + APISETS, // 是否需要强制验证
	GetDownloadLink: URL + '/api/Download', // 下载链接
	GetNewsDeposit: URL + '/api/Announcement/Popup?optionType=Deposit' + APISETS, // 充值公告
	SosBonusVerifications: URL + '/api/SosBonus/Verifications' + APISET, // 电子游戏紧急救援金 第一步验证
	SosBonusApplications: URL + '/api/SosBonus/Applications' + APISET, // 老虎金紧急救援金 第二步，第一步验证成功后 调用此接口
	LatteryHistory: URL + '/api/CMS/Promotions/Applications', // 彩金历史-其他
	ApplicationsByDate: URL + '/api/Bonus/ApplicationsByDate',
	POSTBonusApplications: BffscApiURL + '/api/Bonus/v2.0/Applications' + API8SET, //申請紅利
	ALBStatus: URL + '/api/Payment/UpdateIsQRLocalAliPay?', // 支付宝转账是否成功充值 回馈
	livemgid: URL + '/api/Games/', // 获取game mg live 的 id
	Verifytest: URL + '/api/Member/Prohibited/Verify' + APISET, // 检测用户
	Domaincheck: BffscApiURL + '/api/App/Domain?hostName=' + LOCAL_HOST + API8SETS,
	// LuckySpin: URL + '/api/MiniGames/LuckySpin?eventType=DoubleEleven' + APISETS,// 輪盤
	RebateBonus: URL + '/api/Bonus/RebateBonus?', // 彩金历史
	RebateBonusDetail: URL + '/api/Rebate/RebateDetails' + APISET, // 我的返水详情
	DayBonusHistory: URL + '/api/Bonus/RebateBonusSummary' + APISET,
	RefreshTokenapi: BffscApiURL + '/api/Auth/RefreshToken' + API8SET /* 刷新token */,
	getBannerURL: URL + '/api/CMS/Banners?pageType=Banner&isLogin=false&category=' /* 获取banner */,
	NewPlayerSurvey: URL + '/api/Member/NewPlayerSurvey' + APISET,
	GetNewsWithdrawal: URL + '/api/Announcement/Popup?optionType=Withdrawal' + APISETS,
	GetNewsTransfer: URL + '/api/Announcement/Popup?optionType=Transfer' + APISETS,
	GetNewsSportsbook: URL + '/api/Announcement/Popup?optionType=Sportsbook' + APISETS,
	GetNewsCasino: URL + '/api/Announcement/Popup?optionType=Casino' + APISETS,
	GetNewsSlot: URL + '/api/Announcement/Popup?optionType=Slot' + APISETS,
	PostLinkVerification: URL + '/api/Member/LinkVerification' + APISET,
	GetQuestions: BffscApiURL + '/api/Setting/MasterData/SecurityQuestions' + API8SET /* 获取密保问题 */,
	Gameslist: URL + '/api/Games/Casino/Providers?vendorPlatform=web' + APISETS /* 新的api 获取游戏列表 */,
	RegistrationBonus: URL + '/api/Bonus/RegistrationSuccess' + APISET /* 获取新用户注册优惠*/,

GameCategory:    StrapiApi + "/zh-hans/Games/Providers/Sequence",
        /* 新的api 获取头部导航菜单列表 */
    GetGameMaintenanceStatus_repeated:
        URL +
        "/api/Games/Navigation/MaintenanceStatus" /* 獲取tab 菜單的狀態 */,
    CmsSubCategory:
        StrapiApi + "/zh-hans/Games/Categories/Details" /* 新的api 获取分类游戏列表 */,


    CmsGames: StrapiApi + "/zh-hans/Games",

	Opengame: BffscApiURL + '/api/Games/Launch/' + API8SET,
	PromApplications: URL + '/api/CMS/Promotions/Applications' + APISET, // 申请表格优惠
	RewardURLs: URL + '/api/App/RewardURLs',
	GetTemplateCategories: URL + '/api/News/TemplateCategories' + APISET, // 获取消息中心分类类别（暂时写死，不使用！）
	GetProductCategories: BffscApiURL + '/api/Games/DailyTurnover/ProductTypes' + API8SET, // 获取投注记录筛选分类
	GetTLCPoint: URL + '/api/Member/MemberRewardDetail' + APISET, // 获取同乐币、会员等级、投注额度等信息
	GetTLCRecords: URL + '/api/Reward/RewardDetails' + APISET, // 获取兑换记录
	GetFishUrl: URL + '/api/MiniGames/Fishing' + APISET, // 获取捕鱼链接
	ReferrerLinkActivity: URL + '/api/Quelea/ReferrerLinkActivity' + APISET, // 点击链接数量控制
	ReferrerEligible: BffscApiURL + '/api/Quelea/ReferrerEligible' + API8SET, // 获取推荐人资格条件
	GetQueleaInfo: BffscApiURL + '/api/Quelea/ReferrerInfo' + API8SET, // 获取推荐人详情
	ReferrerRewardStatus: BffscApiURL + '/api/Quelea/ReferrerRewardStatus' + API8SET, // 获取推荐人奖励状态
	ReferrerSignUp: BffscApiURL + '/api/Quelea/ReferrerSignUp' + API8SET, // 注册会员成为Quelea推荐人
	GetQueleaActiveCampaign: BffscApiURL + '/api/Quelea/ActiveCampaign' + API8SET, // 获取最新Quelea推荐活动详情
	ReferrerActivity: BffscApiURL + '/api/Quelea/ReferrerActivity' + API8SET, // 获取被推荐人任务详情
	ReferreeTaskStatus: BffscApiURL + '/api/Quelea/RefereeTaskStatus' + API8SET, // 获取被推荐人任务状态
	Announcements: BffscApiURL + '/api/Announcement/Announcements' + API8SET, // 获取按类别过滤的公告列表(GET)
	ActionOnAnnouncement: BffscApiURL + '/api/Announcement/ActionOnAnnouncement' + API8SET, // 更新每个成员的每个公告项目的读取/打开状态(PATCH)
	AnnouncementIndividualDetail: BffscApiURL + '/api/Announcement/AnnouncementIndividualDetail' + API8SET, // 通过ID获取公告项(GET)
	InboxMessages: BffscApiURL + '/api/PersonalMessage/InboxMessages' + API8SET, // 通过->消息类型ID和选项ID获取成员收件箱消息。(GET)
	InboxMessageIndividualDetail: BffscApiURL + '/api/PersonalMessage/InboxMessageIndividualDetail' + API8SET, // 通过消息ID获取消息。(GET)
	ActionOnInboxMessage: BffscApiURL + '/api/PersonalMessage/ActionOnInboxMessage' + API8SET, // 更新消息状态，将IsOpen和IsRead设置为true。(PATCH)
	UnreadCounts: BffscApiURL + '/api/PersonalMessage/UnreadCounts' + API8SET,
	MarkStatistics: URL + '/api/Member/Statistics?key=unreadPMACount' + APISETS, // 获取全部消息的未读数量 旧版本的三个值为(UnreadTransactionCounts, UnreadPersonalMessagCounts, UnreadAnnouncementCounts)
	GetSecurityCode: BffscApiURL + '/api/Auth/GeneratePasscode' + API8SET, // 获取安全码
	GetDepositorNames: BffscApiURL + '/api/Verification/GetDepositorNames' + API8SET,
	GetDepositorNameVerificationLink: BffscApiURL + '/api/Verification/GetDepositorNameVerificationLink' + API8SET,
	GetCryptocurrencyInfo: URL + '/api/Payment/Cryptocurrency/Details' + APISET, //极速虚拟币支付
	SuggestedAmount: URL + '/api/Payment/SuggestedAmounts', // 推荐金额
	GetExchangeRate: URL + '/api/Payment/Cryptocurrency/ExchangeRate', //获取泰达币提现汇率
	GETWithdrawalDetailsAPI: URL + '/api/Payment/Method/Details?transactionType=Withdrawal' + APISETS, // 获取某个方式的提款详情
	GETWithdrawalDetailsAPI2: BffscApiURL + '/api/Payment/Methods/Details?transactionType=Withdrawal' + APISETS + '&hostName={hostName}&paymentMethod={paymentMethod}&methodCode={methodCode}', // 获取某个方式的提款详情
	AddExchangeRateWallet2: BffscApiURL + '/api/Payment/Cryptocurrency/WalletAddress' + API8SET, // 添加
	CheckExchangeRateWallet2: BffscApiURL + '/api/Payment/Cryptocurrency/WalletAddress' + API8SET, // 查询
	AddExchangeRateWallet: URL + '/api/Payment/Cryptocurrency/WalletAddress' + APISET, // 添加虚拟货币錢包
	CheckExchangeRateWallet: URL + '/api/Payment/Cryptocurrency/WalletAddress', // 查询会员虚拟货币錢包
	setTDBDefaultWallet: URL + '/api/Payment/Cryptocurrency/WalletAddress/Default', //设置默认钱包

	Captchaswitch: URL + '/api/Member/Captcha' + APISET, //极验开关
	ConfiscatedMemberVerification: BffscApiURL + '/api/Member/ConfiscatedMemberVerification' + API8SET, //失效帳號驗證/被關閉帳號提示
	ConfiscatedMemberVerifyAttempts: URL + '/api/Member/ConfiscatedMemberVerifyAttempts' + APISET, //獲取次數 > 失效帳號驗證/被關閉帳號提示
	ConfiscatedAccountInfoValidation: URL + '/api/Member/ConfiscatedAccountInfoValidation' + APISET, //失效帳號驗證/被關閉帳號提示

	CheckIsAbleSmsOTP: URL + '/api/Verification/Payment/Phone' + APISET, // 查询会员是否能够请求短信OTP
	CheckIsAbleSmsOTPByServiceAction: URL + '/api/Verification/Payment/Phone?serviceAction={serviceAction}' + APISETS, // 查询会员是否能够请求短信OTP
	PostSendSmsOTP: URL + '/api/Verification/Payment/Phone', // 提交请求短信OTP
	PostVerifySmsOTP: URL + '/api/Verification/Payment/Phone', // 返回是否成功验证短信OTP
	SurveySubmit: URL + '/api/LiveChat/MemberSurvey/Feedback' + APISET, // 客户服务评价
	SurveyVerifyLink: URL + '/api/LiveChat/MemberSurvey/VerifyLink' + APISET, // 客户服务评价链接是否有效

	CheckWalletPreBonus: URL + '/api/Bonus/CheckWalletPreBonus' + APISET, // 检查

	CheckWithdrawalThreshold: URL + '/api/Payment/Transaction/CheckWithdrawalThreshold', //查看用户提款卡是否受限制
	GetWithdrawalThresholdHistory: URL + '/api/Payment/Transaction/WithdrawalThresholdHistories', //获取会员已完成某次提款的门槛记录
	GetWithdrawalThresholdLimit: URL + '/api/Payment/Transaction/WithdrawalThresholdLimit' + APISET, //获取银行卡咨询提款限制的tip
	SetWithdrawalLimit: URL + '/api/Payment/Transaction/MemberWithdrawalThreshold' + APISET, //设置提款额度
	GetMemberWithdrawalThreshold: URL + '/api/Payment/Transaction/MemberWithdrawalThreshold' + APISET, //获取提款限制

	InvoiceAutCryptoDeposit: URL + '/api/Payment/Cryptocurrency/ProcessInvoiceAutCryptoDeposit', //虚例币支付二我已成功充值提交
	InvoiceAutCancelTheDeal: URL + '/api/Payment/Application/MemberCancelDeposit', //虚例币支付二取消交易
	FastvirtualCurrencyPaymentTwo: URL + '/api/Payment/Transaction/ProcessingDepositbyMethod' + APISET, //虚例币支付二
	GenerateRedirectToken: URL + '/api/Member/GenerateRedirectToken' + APISET, // 获取转接8号商城token
	VerifyRedirectToken: URL + '/api/Member/VerifyRedirectToken' + APISET, // 验证首页跳转token
	CancelPaybnbDeposit: URL + '/api/Payment/Applications/Transactions/CancelPaybnbDeposit' + APISET + '&', // 本地银行转账银行账户无法使用时取消操作回调

    SubmitResetPasswordLink: BffscApiURL + "/api/Auth/ResetPassword" + API8SET, // 新增重置密码链接
    PhonePrefix: BffscApiURL + "/api/Setting/Phone/Prefix" + API8SET, // 验证注册手机号前缀
    // Feedbackform: URL + '/api/member/feedbackform' + APISET, // USDT介绍问题反馈
    Feedbackform: URL + "/api/LiveChat/USDT/Feedback" + APISET, // USDT介绍问题反馈
    VerifyResetPasswordLink: BffscApiURL + "/api/Verification/Email/Token" + API8SET, // 重置密码EncText验证
    CheckWalletForBonus: URL + "/api/Bonus/CheckWalletForBonus" + APISET, // 直接申请优惠前置判定
    DirectApplyBonus: URL + "/api/Bonus/DirectApplyBonus" + APISET, // 直接申请优惠请求接口
    ProvidersDetails: StrapiApi + "/zh-hans/Games/Providers/Details", // 游戏平台接口
    GetProvinces: BffscApiURL + "/api/Setting/MasterData/ShippingAddress?type=Province" + API8SETS, // 省
    GetDistricts: BffscApiURL + "/api/Setting/MasterData/ShippingAddress?type=District" + API8SETS, // 地区
    GetTowns: BffscApiURL + "/api/Setting/MasterData/ShippingAddress?type=Town" + API8SETS, // 城镇
    GETisSafeHouse: BffscApiURL + "/api/App/Domain?hostName=" + LOCAL_HOST + API8SETS, //判斷安全屋 domain
    Safehouse: BffscApiURL + "/api/Member/Safehouse" + API8SET, //安全屋登入前检查
    SetChangePassword:
        BffscApiURL + "/api/Auth/ChangePassword" + API8SET + "&oldPasswordRequired=false", //更新密码
    /** 欧冠API  <------- */
    // 获取欧冠分组信息
    Groups:
        SPORT_API +
        "/sports-data-gateway/api/v2.0/brands/TLC/groups" +
        "?currentPage=1&perPageSize=50&platform=WEB&seasonId=24&type=league",
    // 获取比赛信息，例如球队得分，赛事开始日期，状态，赔率等
    Matches:
        SPORT_API +
        "/sports-data-gateway/api/v2.0/brands/TLC/matches" +
        "?currentPage=1&perPageSize=1000&platform=WEB&seasonId=24&type=league",
    // 按团队ID（组类别）获取球员统计数据
    PlayersStats:
        SPORT_API +
        "/sports-data-gateway/api/v2.0/brands/TLC/players/stats/189" +
        "?currentPage=1&perPageSize=50&platform=WEB&seasonId=24&type=league",
    // 通过团队ID，组类别ID获取团队统计信息
    GetTeamStatus:
        SPORT_API +
        "/sports-data-gateway/api/v2.0/brands/TLC/teams/stats" +
        "?id=189&currentPage=1&perPageSize=50&platform=WEB&seasonId=24&type=league",
    // 更新输赢几率  2021/4/7  暂不对接
    PutTeamStatus:
        SPORT_API + "/sports-data-gateway/api/v2.0/brands/TLC/team/status",
    /** 欧冠API  -------> */
	/** 欧冠API  <------- */
	// 获取欧冠分组信息
	Groups:
		SPORT_API +
		'/sports-data-gateway/api/v2.0/brands/TLC/groups' +
		'?currentPage=1&perPageSize=50&platform=WEB&seasonId=24&type=league',
	// 获取比赛信息，例如球队得分，赛事开始日期，状态，赔率等
	Matches:
		SPORT_API +
		'/sports-data-gateway/api/v2.0/brands/TLC/matches' +
		'?currentPage=1&perPageSize=1000&platform=WEB&seasonId=24&type=league',
	// 按团队ID（组类别）获取球员统计数据
	PlayersStats:
		SPORT_API +
		'/sports-data-gateway/api/v2.0/brands/TLC/players/stats/189' +
		'?currentPage=1&perPageSize=50&platform=WEB&seasonId=24&type=league',
	// 通过团队ID，组类别ID获取团队统计信息
	GetTeamStatus:
		SPORT_API +
		'/sports-data-gateway/api/v2.0/brands/TLC/teams/stats' +
		'?id=189&currentPage=1&perPageSize=50&platform=WEB&seasonId=24&type=league',
	// 更新输赢几率  2021/4/7  暂不对接
	PutTeamStatus: SPORT_API + '/sports-data-gateway/api/v2.0/brands/TLC/team/status',
	/** 欧冠API  -------> */

	/** 双十一2021 <------- */
	LuckySpinHistoryDouble:
		URL +
		'/api/MiniGames/LuckySpin/History?dateFrom=2021-10-22T00:00:00&dateTo=2021-11-30T23:59:59&eventType=DoubleEleven' +
		APISETS, // 获取抽奖记录  2021-09-17&dateTo=2021-09-21&eventType=MidAutumn
	LuckySpinDouble: URL + '/api/MiniGames/LuckySpin?eventType=DoubleEleven' + APISETS, // 抽奖
	/** 双十一2021  -------> */

	/** 红包雨2022 <------- */
	RainLuckySpinHistory:
		URL +
		'/api/MiniGames/LuckySpin/History?dateFrom=2021-12-16T00:00:00&dateTo=2022-03-10T23:59:59&eventType=RainingPromotion' +
		APISETS, // 获取抽奖记录  2021-09-17&dateTo=2021-09-21&eventType=MidAutumn
	RainLuckySpin: URL + '/api/MiniGames/LuckySpin?eventType=RainingPromotion' + APISETS, // 抽奖
	/** 红包雨2022  -------> */

	/** 交易记录新版2021 <------- */
	SubWithdrawal: URL + '/api/Payment/Applications', // 交易记录 充值、提现
	BankingHistory: URL + '/api/Payment/Application/BankingHistory' + APISET, // 交易记录 充值、提现
	TransferHistory: URL + '/api/Transfer/Application' + APISET, // 交易记录 转账
	UploadAttachment: URL + '/api/Payment/Application/UploadAttachment' + APISET, // 上传交易凭证
	GetResubmitDepositDetails: URL + '/api/Payment/Transaction/ResubmitDepositDetails' + APISET, // 重新提交的交易详情
	GetTransactionDetail: URL + '/api/Payment/Transaction/History' + APISET, // 交易记录详情
	MemberRequestDepositReject: URL + '/api/Payment/Application/MemberRequestDepositReject' + APISET, // 取消充值
	MemberRequestDepositReject2: BffscApiURL + '/api/Payment/Applications/MemberRequestDepositReject' + APISET, // 取消充值
	CreateResubmitOnlineDeposit: URL + '/api/Payment/Transaction/CreateResubmitOnlineDeposit' + APISET, // 重新提交充值
	FundNotReceivedRequest: URL + '/api/Payment/Transaction/FundNotReceivedRequest' + APISET,
	/** 交易记录新版2021  -------> */

	/** 更改注册成功UI，提交是否参加首充验证优惠 <------- */
	MemberHighPriorityCall: URL + '/api/CallAgent/MemberHighPriorityCall' + APISET,
	/** 更改注册成功UI，提交是否参加首充验证优惠  -------> */

	/** 新增身份证完善、手机号验证可更新一次逻辑 <------- */
	IsPhoneAllowedEdit: URL + '/api/member/IsPhoneAllowedEdit' + APISET, // 获取手机号是否可更新
	GetOTPAttempts: BffscApiURL + '/api/Verification/OTPAttempts' + API8SET, // 获取手机号尝试次数
	/** 新增身份证完善、手机号验证可更新一次逻辑  -------> */
	//获取邮箱验证剩余尝试次数
	GetEmailOTPAttempts: URL + '/api/Verification/Email/GetOTPAttempts' + APISET,

	ConfirmWithdrawalComplete: URL + '/api/Payment/Applications/ConfirmWithdrawalComplete',
	CaptchaInfo: BffscApiURL + '/api/Verification/Captcha/Info'  + API8SET, //获取滑动验证码验证记录
	//登录图形验证获取id
	RequestCaptchaChallengeId: BffscApiURL + '/api/Verification/Captcha/ChallengeId' + API8SET,
	//上传验证银行卡和身份证照片信息
	BanksVerificationUploadFile: URL + '/api/Payment/MemberBanksVerificationAttachment' + APISET,
	MemberBanksVerificationManual: URL + '/api/Payment/MemberBanksVerificationManual' + APISET,
	//PII验证银行卡获取验证次数
	GetBankcardVerificationAttempts: URL + '/api/Payment/GetBankcardVerificationAttempts' + APISET,

	GetProvidersMaintenanceStatus: URL + '/api/Games/GetProvidersMaintenanceStatus?Provider=IPSB' + APISETS, //IM体育是否能正常
	//获取紧急公告
	getNews: BffscApiURL + '/api/Announcement/Popup' + API8SET,
	//已读该条公告
	readNews: URL + '/api/News/UpdateAnnouncementNotShowAgainFlag' + APISET,
	//获取会员VIP级别和它的进展
	VipLevels: URL + '/api/Reward/VipLevels' + APISET,
	TurnoverProgress: URL + '/api/Reward/TurnoverProgress' + APISET,
	//获取VIP优惠申请列表
	GetVIPBonus: URL + '/api/Bonus/VIPBonus' + APISET,
	// 获取会员级别是否被更改
	GetIsLevelChanged: URL + '/api/Reward/IsLevelChanged' + APISET,
	//确认后更新会员级别
	PUTAcknowledgeLevelChanged: URL + '/api/Reward/AcknowledgeLevelChanged' + APISET, //按照游戏类别获取同乐币和有效流水的兑换率  GetProductTurnoverPointConversion: URL + '/api/Reward/ProductTurnoverPointConversion' + APISET,
	//小额提款第二次建议金额
	GetPrefixAmount: URL + '/api/Payment/ClosestPrefixAmount' + APISET,
	// 每日好禮APICMS
	CMSGetDailydealsList: StrapiApi + '/zh-hans/api/v1/web/promotions?type=daily' + APISETS,
	//每日好礼上方banner
	ShippingAddress: BffscApiURL + '/api/Member/ShippingAddress' + API8SET,
	//提交每日好礼申请
	PostDailyDeals: BffscApiURL + '/api/Promotion/Application' + API8SET,
	ThroughoutVerification: BffscApiURL + '/api/Quelea/ThroughoutVerification' + API8SET /*获取好友推荐的优惠逻辑 */,
	GetVerificationMemberDocuments: BffscApiURL + '/api/Verification/MemberDocuments' + API8SET, //获取上传文档的进度
	GetDocumentApprovalStatus: BffscApiURL + '/api/Verification/DocumentApprovalStatus' + API8SET, //获取是否需要上传文档的状态
	UploadDocument: BffscApiURL + '/api/Verification/MemberDocument/Upload' + API8SET, //上传身份信息资料
	WithdrawalVerification: URL + '/api/Setting/WithdrawalVerification', //获取提款之前验证资料
	AccountHolderName: BffscApiURL + '/api/Verification/AccountHolderName' + API8SET, //提款需要验证文件的弹窗，点击立即验证按钮触发此api
	GetGameMaintenanceStatus: BffscApiURL + '/api/Games/Navigation/MaintenanceStatus' + API8SET,

	//2024 欧洲杯
	GetEuroEvent: BffscApiURL + "/api/Event/MiniGames/ActiveGame"+ API8SET,
	PostSnatchprize : BffscApiURL + "/api/Event/MiniGames/SnatchPrize" + API8SET,
	GetPrizeHistory: BffscApiURL + "/api/Event/MiniGames/PrizeHistory" + API8SET,
	GetPMemberProgress: BffscApiURL + "/api/Event/MiniGames/MemberProgress" + API8SET,
	GetEuroCustomFlag: BffscApiURL +"/api/Member/CustomFlag?flagKey=TeamPreferenceWC22"+API8SETS,
	GetEuroFootballTournamentTeam: BffscApiURL + "/api/Member/FootballTournamentTeam"+APISET,
	PostTeamPreferencesWC22: URL + "/api/Member/TeamPreferencesWC22"+APISET,
	GetMemberSuperdoorShownStatus: BffscApiURL + "/api/Auth/MemberSuperdoorShownStatus" + API8SET,
	PutUpdateMemberSuperdoorShownStatus: BffscApiURL + "/api/Member/UpdateMemberSuperdoorShownStatus" + API8SET,

	/* ----------------------------------------------------------------------------------------- */

	/* 整合CMS和BFF API */
	// PromotionCategories: StrapiCmsUrl + '/zh-hans/api/v1/promotion/categories',
	PromotionCategories: StrapiApi + '/zh-hans/api/v1/promotion/categories',
	RebateRunningDetails: BffscApiURL + '/api/Promotion?type=Rebate&subtype=RunningDetails' + API8SETS, // 返水細節
	RebateHistories: BffscApiURL + '/api/Promotion/Histories?type=Rebate' + API8SETS, // 返水歷史
	FreebetBonusGroups: URL + '/api/Promotion/Freebet/BonusGroups' + APISET, // 免費投注群組
	AppliedHistory: BffscApiURL + '/api/Bonus/AppliedHistory' + API8SET, // 應用歷史
	DailyDealsHistories: BffscApiURL + '/api/Promotion/Histories?type=DailyDeals' + API8SETS, // 每日好礼记录
	BonuslistAPI: BffscApiURL + '/api/Bonus/v2.0' + API8SET, // 获取存款红利
	GETDailyDeals: BffscApiURL + '/api/Promotion?type=DailyDeals' + API8SETS, //提交每日好礼申请
	MemberPromoHistories: BffscApiURL + '/api/Promotion/Histories?type=ManualPromo' + API8SETS, // 会员促销历史
	CMSRebateList: StrapiApi + '/zh-hans/api/v1/web/promotions?type=rebate', // 反水清單
	CMSPromotionDetail: StrapiApi + '/zh-hans/api/v1/web/promotion?', // api优惠列表
	CMSPromotionList: StrapiApi + '/zh-hans/api/v1/web/promotions?',
	CMSAppliedHistory: StrapiApi + '/cms/promotions-m1/',
	CMSRebateHistory: StrapiApi + '/cms/promotions-m1/rebateids/',
	CMSConfigs: StrapiApi + '/cms/zh-hans/fe-configs', //获取天王俱乐部链接
	fetchPOSTRewardTokenEncry: BffscApiURL  + '/api/Auth/RewardToken/Encryption' + API8SET, //获取天王俱乐部auto-login token
	fetchPOSTRewardTokenDecry: BffscApiURL  + '/api/Auth/RewardToken/Decryption' + API8SET,
	/* ----------------------------------------------------------------------------------------- */

    GETFooter: StrapiApi +  "/cms/footer-m1/desktop",
    SmarticoToggle: StrapiApi +  "/cms/mainsite-config-m1",
};
